const Maintenance = () => {
  return (
    <div className='wrapper'>
      <img
        src='https://ohmyhome.com/wp-content/uploads/2023/07/OMH_Logo_Tagline_XL.png'
      />
      <p>Ohmyhome website is currently under maintenance.</p>
      <p>
        Our system will be unavailable on&nbsp;
        <b>31 August 2023 from 3:00AM to 5:00AM (GMT+8)</b>.
      </p>
      <style jsx>{`
      .wrapper {
        /* height: 100vh; */
        padding-left: 10%;
        padding-right: 10%;
      }

      div {
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      div > img {
        height: 64px;
        margin-bottom: 16px;
      }

      div > p {
        font-size: 16px;
        margin-bottom: 0;
        text-align: center;
      }
      `}
      </style>
    </div>
  )
}

export default Maintenance
