import { logger } from 'common/utils/logger'
import React from 'react'

interface ErrorBoundryProps{
  fallback: React.ReactNode
  children: React.ReactNode
}

class ErrorBoundary extends React.Component<ErrorBoundryProps> {
  state = { hasError: false }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error: Error, errorInfo: React.ErrorInfo): void {
    logger.error('[App Crashed][Javascript Error]', error, errorInfo)
  }

  render () {
    if (this.state.hasError) {
      return (
        this.props.fallback
      )
    }
    return this.props.children
  }
}

export { ErrorBoundary }
