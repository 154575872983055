import { Link } from '@mui/material'
import Stack from '@mui/system/Stack'
import MuiTypography from 'common/components/Typography/MuiTypography'

const PageFooter = ({ ...props }) => {
  return (
    <Stack
      direction='row'
      id='app-layout-footer'
      component='footer'
      width='100%'
      justifyContent='center'
      alignItems='center'
      gap={0.25}
      mb={1}
      {...props}
    >
      <MuiTypography
        variantStyle='Body2'
        text='© 2023 Ohmyhome •'
        color='rgba(0,0,0,0.6)'
        fontSize={{ xs: '12px', sm: '14px' }}
        mt={{ xs: 0.75, sm: 0.5 }}
      />
      <Link
        href={`${process.env.baseURL}/en-sg/privacy-policy/`}
        underline='none'
        target='_blank'
        rel='noreferrer'
      >
        <MuiTypography
          variantStyle='Body2'
          text='Privacy Policy'
          color='rgba(0,0,0,0.6)'
          fontSize={{ xs: '12px', sm: '14px' }}
        />
      </Link>
      <MuiTypography
        variantStyle='Body2'
        text=' •'
        color='rgba(0,0,0,0.6)'
        fontSize={{ xs: '12px', sm: '14px' }}
        mt={{ xs: 0.75, sm: 0.5 }}
      />
      <Link
        href={`${process.env.baseURL}/en-sg/terms-of-use/`}
        underline='none'
        target='_blank'
        rel='noreferrer'
      >
        <MuiTypography
          variantStyle='Body2'
          text='Terms of Service'
          color='rgba(0,0,0,0.6)'
          fontSize={{ xs: '12px', sm: '14px' }}
        />
      </Link>
    </Stack>
  )
}

export { PageFooter }
