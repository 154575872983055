import { createTheme } from '@mui/material/styles'

import React from 'react'
import { breakpointValues } from './breakingPoints'

const themeFontFamily = {
  primary: ['Plus Jakarta Sans', 'sans-serif'].join(',')
}

const typographyTheme = createTheme({
  breakpoints: {
    values: breakpointValues
  }
})

const typographyValue = {
  Display1: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    lineHeight: '110%',
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '36px',
      letterSpacing: '-0.72px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '40px',
      letterSpacing: '-0.8px'
    }
  },
  Display2: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    lineHeight: '120%',
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '24px',
      letterSpacing: '-0.48px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '32px',
      letterSpacing: '-0.64px'
    }
  },
  Title1: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 600,
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '120%',
      letterSpacing: '-0.48px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '130%',
      letterSpacing: '-0.56px'
    }
  },
  Title2: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 600,
    lineHeight: '140%',
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '20px',
      letterSpacing: '-0.4px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '24px',
      letterSpacing: '-0.48px'
    }
  },
  Title3: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '120%',
      letterSpacing: '-0.36px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '130%',
      letterSpacing: '-0.18px'
    }
  },
  Subtitle1: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 600,
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '120%',
      letterSpacing: '-0.16px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '130%',
      letterSpacing: '-0.18px'
    }
  },
  Subtitle2: {
    fontFamily: themeFontFamily.primary,
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '-0.16px',
    [typographyTheme.breakpoints.down('sm')]: {
      lineHeight: '120%'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      lineHeight: '130%'
    }
  },
  Button1: {
    fontFamily: themeFontFamily.primary,
    fontSize: '16px',
    fontWeight: 700,
    [typographyTheme.breakpoints.down('sm')]: {
      lineHeight: '100%'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      lineHeight: '110%'
    }
  },
  Button2: {
    fontFamily: themeFontFamily.primary,
    fontSize: '14px',
    fontWeight: 700,
    [typographyTheme.breakpoints.down('sm')]: {
      lineHeight: '100%'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      lineHeight: '110%'
    }
  },
  Body1: {
    fontFamily: themeFontFamily.primary,
    fontSize: '16px',
    letterSpacing: '-0.16px',
    lineHeight: '150%'
  },
  Body2: {
    fontFamily: themeFontFamily.primary,
    fontSize: '14px',
    letterSpacing: '-0.14px',
    [typographyTheme.breakpoints.down('sm')]: {
      lineHeight: '150%'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      lineHeight: '140%'
    }
  },
  Caption1: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '157%',
    letterSpacing: '-0.12px'

  },
  Overline1: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    lineHeight: '125%',
    textTransform: 'uppercase' as React.CSSProperties['textTransform'],
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '12px',
      letterSpacing: '1.2px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '14px',
      letterSpacing: '1.12px'
    }
  },
  Overline2: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '125%',
    textTransform: 'uppercase' as React.CSSProperties['textTransform'],
    [typographyTheme.breakpoints.down('sm')]: {
      letterSpacing: '0.6px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      letterSpacing: '0.96px'
    }
  },
  UI1: {
    fontFamily: themeFontFamily.primary,
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '130%',
      letterSpacing: '-0.14px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '110%',
      letterSpacing: '-0.16px'
    }
  },
  UI2: {
    fontFamily: themeFontFamily.primary,
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '130%',
      letterSpacing: '-0.12px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '110%',
      letterSpacing: '-0.14px'
    }
  },
  UI3: {
    fontFamily: themeFontFamily.primary,
    fontSize: '12px',
    [typographyTheme.breakpoints.down('sm')]: {
      lineHeight: '130%',
      letterSpacing: '-0.12px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      lineHeight: '110%'
    }
  },
  UI4: {
    fontFamily: themeFontFamily.primary,
    fontSize: '10px',
    lineHeight: '130%'
  },
  UI5: {
    fontFamily: themeFontFamily.primary,
    fontSize: '8px',
    [typographyTheme.breakpoints.down('sm')]: {
      lineHeight: '130%'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      lineHeight: '125%'
    }
  },
  Numeral1: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    lineHeight: '110%',
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '36px',
      letterSpacing: '-1.44px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '40px',
      letterSpacing: '-1.6px'
    }
  },

  Numeral2: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '28px',
      lineHeight: '110%',
      letterSpacing: '-1.12px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '32px',
      lineHeight: '120%',
      letterSpacing: '-1.28px'
    }
  },

  Numeral3: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '110%',
      letterSpacing: '-0.8px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '28px',
      lineHeight: '140%',
      letterSpacing: '-0.56px'
    }
  },
  Numeral4: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    lineHeight: '140%',
    [typographyTheme.breakpoints.down('sm')]: {
      fontSize: '20px',
      letterSpacing: '-0.8px'
    },
    [typographyTheme.breakpoints.up('sm')]: {
      fontSize: '24px',
      letterSpacing: '-0.48px'
    }
  },
  Numeral5: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '110%',
    letterSpacing: '-0.48px'
  },
  Numeral6: {
    fontFamily: themeFontFamily.primary,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '160%',
    letterSpacing: '-0.42px'
  }
}

export { themeFontFamily, typographyTheme, typographyValue }
