import { alpha } from '@mui/material'

const ink = '#021217'

export const themeColors = {
  ink: {
    5: alpha(ink, 0.05),
    10: alpha(ink, 0.1),
    20: alpha(ink, 0.2),
    30: alpha(ink, 0.3),
    65: alpha(ink, 0.65),
    80: alpha(ink, 0.8),
    90: alpha(ink, 0.9),
    100: ink
  },
  orange: {
    ohmyhome: '#EF6F09',
    100: '#FEF5EF',
    300: '#F48C69',
    500: '#F25D2B',
    700: '#B64620',
    900: '#993616'
  },
  mandarin: {
    100: '#FEF0E7',
    300: '#F0AA75',
    500: '#EA7806',
    700: '#B65D16',
    900: '#5A3110'
  },
  azure: {
    100: '#E5F7FA',
    300: '#A5DDE9',
    500: '#15B2D5',
    700: '#0385B0',
    900: '#005684'
  },
  lime: {
    100: '#FEF0E7',
    300: '#6DC98F',
    500: '#19B15F',
    700: '#167042',
    900: '#083A20'
  },
  lemon: {
    100: '#FFFAF0',
    300: '#FFDF99',
    500: '#F8A807',
    700: '#C58907',
    900: '#664805'
  },
  grapefruit: {
    100: '#FFF7F5',
    300: '#FA776B',
    500: '#DE2E0B',
    700: '#912711',
    900: '#6B261B'
  }
}
