
export const logger = {
  log: (...args: any) => {
    if (typeof (window) === 'undefined') {
      console.log(args)
      return
    }

    fetch('/api/log/text', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(args)
    })
  },
  error: (...args: any) => {
    if (typeof (window) === 'undefined') {
      console.error('[SERVER-ERROR]', args)
      return
    }

    fetch('/api/log/error', {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(args)
    })
  }

}
