import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Button, Container, Link, Paper, Stack, Typography } from '@mui/material'
import { PageFooter } from 'common/components/Page/Footer'
import { useRouter } from 'next/router'
import MuiTypography from '../Typography/MuiTypography'

const ErrorPage = () => {
  const router = useRouter()

  const handleRefresh = () => {
    router.reload()
  }

  return (
    <Container sx={{
      px: { xs: 1.5 },
      py: 2
    }}
    >
      <Paper
        variant='outlined'
        sx={{
          maxWidth: 808,
          py: 5,
          px: 3,
          mx: 'auto',
          mt: { xs: '0', sm: 10 }
        }}
      >
        <Stack
          direction='row'
        >
          <Stack
            flex={1}
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            spacing={3}
          >
            <img
              height={27}
              src='/assets/ohmyhome.svg'
              alt='logo'
            />
            <Box
              component='img'
              src='/assets/not-found.svg'
              alt='not-found'
              height={200}
              sx={{
                display: { xs: 'block', sm: 'none' }
              }}
            />
            <Typography fontSize={48}>Oops!</Typography>
            <MuiTypography
              variantStyle='Body2'
              text='Aaah! Something went wrong! Please refresh the page'
              light
              textAlign={{ xs: 'center', sm: 'left' }}
            />
            <Link
              href='/household/'
              underline='none'
            >
              <Button
                variant='contained'
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
              > Refresh
              </Button>
            </Link>
          </Stack>
          <Stack
            flex={1}
            alignItems='flex-end'
            sx={{
              p: 3,
              display: { xs: 'none', sm: 'flex' }
            }}
          >
            <img
              src='/assets/not-found.svg'
              alt='not-found'
              height={200}
            />
          </Stack>
        </Stack>
      </Paper>
      <Box sx={{
        position: { xs: 'relative', sm: 'absolute' },
        bottom: 0,
        left: 0,
        right: 0
      }}
      >
        <PageFooter />

      </Box>
    </Container>
  )
}

export { ErrorPage }
