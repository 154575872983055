import ErrorBoundary, { ErrorPage } from 'common/components/ErrorBoundary'

import { UserProvider } from '@auth0/nextjs-auth0/client'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Maintenance from 'common/components/Maintenance/Maintenance'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import createEmotionCache from 'lib/emotion/createEmotionCache'
import theme from 'lib/theme'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import React, { useEffect, useState } from 'react'

import '@fontsource/plus-jakarta-sans'
import 'lib/theme/global.css'

type AppLayoutProps = AppProps & {
  pageProps: any
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()
const OmhApp = (props: AppLayoutProps) => {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props
  const [queryClient] = React.useState(() => new QueryClient())
  const [mounted, setMounted] = useState(false)
  const { user } = props.pageProps

  useEffect(() => {
    setMounted(true)
  }
  , [])

  /* Fix for Safari bug with overflow hidden property  to resolve this https://github.com/mui/material-ui/issues/5750 */
  useEffect(() => {
    if (globalThis?.document) {
      const body = globalThis.document.body

      const observer = new MutationObserver(() => {
        body.style.touchAction = body.style.overflow === 'hidden' ? 'none' : ''
      })

      observer.observe(body, {
        attributes: true,
        attributeFilter: ['style']
      })
    }
  }, [])

  return (
    <>
      <Head>
        <meta charSet='UTF-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, viewport-fit=cover, maximum-scale=1'
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div style={{ visibility: !mounted ? 'hidden' : 'visible' }}>
                <UserProvider user={user}>
                  <ErrorBoundary fallback={<ErrorPage />}>
                    {process.env.MAINTENANCE_MODE === 'true' ? <Maintenance /> : <Component {...pageProps} />}
                  </ErrorBoundary>
                </UserProvider>
              </div>
            </ThemeProvider>
          </CacheProvider>
          <ReactQueryDevtools
            position='bottom-right'
            initialIsOpen={false}
          />
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}

export default withLDProvider({
  clientSideID: process.env.launchDarklyClient!
})(OmhApp as React.ComponentType<{}>)
