import { Typography, TypographyProps } from '@mui/material'
import { ElementType, FC, ReactNode } from 'react'
import { variantStyles } from './variantStyles'

interface MuiTypographyProps extends TypographyProps {
  text?: string
  light?: boolean
  children?: ReactNode
  variantStyle?: keyof typeof variantStyles
  component?: ElementType<any>
}

const MuiTypography: FC<MuiTypographyProps> = ({ text, light, children, variantStyle = 'Body1', component = 'span', ...props }) => {
  return (
    <Typography
      component={component}
      className={`MuiTypography-${variantStyle}`}
      color={light ? 'ink.65' : ''}
      {...variantStyles[variantStyle]}
      {...props}
    >
      {text ?? children}
    </Typography>
  )
}

export default MuiTypography
