export const variantStyles = {
  H1: {
    fontSize: { xs: 40, sm: 72 },
    lineHeight: { xs: '47px', sm: '84px' },
    fontWeight: 600
  },
  H2: {
    fontSize: { xs: 32, sm: 56 },
    lineHeight: { xs: '32px', sm: '67px' },
    fontWeight: 600
  },
  H3: {
    fontSize: { xs: 28, sm: 48 },
    lineHeight: { xs: '33px', sm: '56px' },
    fontWeight: 600
  },
  H4: {
    fontSize: { xs: 24, sm: 32 },
    lineHeight: { xs: '30px', sm: '40px' },
    fontWeight: 600
  },
  H5: {
    fontSize: { xs: 20, sm: 28 },
    lineHeight: { xs: '26px', sm: '36px' },
    fontWeight: 600
  },
  H6: {
    fontSize: { xs: 18, sm: 24 },
    lineHeight: { xs: '24px', sm: '32px' },
    fontWeight: 600
  },
  H7: {
    fontSize: { xs: 16, sm: 20 },
    lineHeight: { xs: '24px', sm: '30px' },
    fontWeight: 600
  },
  H8: {
    fontSize: { xs: 14, sm: 18 },
    lineHeight: { xs: '22px', sm: '28px' },
    fontWeight: 600
  },
  H9: {
    fontSize: { xs: 14, sm: 16 },
    lineHeight: { xs: '18px', sm: '24px' },
    fontWeight: 600
  },
  H10: {
    fontSize: { xs: 12, sm: 14 },
    lineHeight: { xs: '14px', sm: '20px' },
    fontWeight: 600
  },
  Body1: {
    fontSize: { xs: 14, sm: 16 },
    lineHeight: { xs: '21px', sm: '24px' },
    letterSpacing: '0.15px'
  },
  Body2: {
    fontSize: { xs: 12, sm: 14 },
    lineHeight: { xs: '17px', sm: '20px' },
    letterSpacing: '0.17px'
  },
  Body3: {
    fontSize: { xs: 10, sm: 12 },
    lineHeight: { xs: '15px', sm: '18px' },
    letterSpacing: '0.15px'
  },
  Subtitle1: {
    fontSize: { xs: 14, sm: 16 },
    lineHeight: { xs: '24px', sm: '28px' },
    letterSpacing: '0.1px'
  },
  Subtitle2: {
    fontSize: { xs: 12, sm: 14 },
    lineHeight: { xs: '19px', sm: '22px' },
    letterSpacing: '0.1px'
  },
  Subtitle3: {
    fontSize: { xs: 10, sm: 12 },
    lineHeight: { xs: '15px', sm: '18px' },
    letterSpacing: '0.1px'
  },
  Overline: {
    fontSize: { xs: 12, sm: 12 },
    lineHeight: { xs: '32px', sm: '32px' },
    letterSpacing: '1px'
  },
  Caption: {
    fontSize: { xs: 10, sm: 12 },
    lineHeight: { xs: '17px', sm: '20px' },
    letterSpacing: '0.4px'
  },
  Alert_Title: {
    fontSize: { xs: 14, sm: 16 },
    lineHeight: { xs: '21px', sm: '24px' },
    letterSpacing: '0.15px',
    fontWeight: 500
  },
  Alert_Description: {
    fontSize: { xs: 12, sm: 14 },
    lineHeight: { xs: '17px', sm: '20px' },
    letterSpacing: '0.15px',
    fontWeight: 500
  },
  Avatar_Initials: {
    fontSize: { xs: 16, sm: 20 },
    lineHeight: { xs: '20px', sm: '20px' },
    letterSpacing: '0.14px'
  },
  Badge_Label: {
    fontSize: { xs: 11, sm: 12 },
    lineHeight: { xs: '20px', sm: '20px' },
    letterSpacing: '0.14px',
    fontWeight: 500
  },
  Button_Large: {
    fontSize: { xs: 14, sm: 16 },
    lineHeight: { xs: '26px', sm: '26px' },
    letterSpacing: '0.46px',
    fontWeight: 600
  },
  Button_Medium: {
    fontSize: { xs: 12, sm: 14 },
    lineHeight: { xs: '24px', sm: '24px' },
    letterSpacing: '0.4px',
    fontWeight: 600
  },
  Button_Small: {
    fontSize: { xs: 10, sm: 12 },
    lineHeight: { xs: '22px', sm: '22px' },
    letterSpacing: '0.46px',
    fontWeight: 600
  },
  Bottom_Navigation: {
    fontSize: { xs: 14, sm: 14 },
    lineHeight: { xs: '23px', sm: '23px' },
    letterSpacing: '0.4px'
  },
  Chip_Label: {
    fontSize: { xs: 12, sm: 12 },
    lineHeight: { xs: '18px', sm: '18px' },
    letterSpacing: '0.16px'
  },
  Helper_Text: {
    fontSize: { xs: 10, sm: 10 },
    lineHeight: { xs: '13px', sm: '13px' },
    letterSpacing: '0.4px'
  },
  Input_Label: {
    fontSize: { xs: 12, sm: 12 },
    lineHeight: { xs: '12px', sm: '12px' },
    letterSpacing: '0.15px'
  },
  Input_Text_Large: {
    fontSize: { xs: 14, sm: 16 },
    lineHeight: { xs: '24px', sm: '24px' },
    letterSpacing: '0.15px'
  },
  Input_Text_Medium: {
    fontSize: { xs: 12, sm: 14 },
    lineHeight: { xs: '20px', sm: '20px' }
  },
  List_Subheader: {
    fontSize: { xs: 14, sm: 14 },
    lineHeight: { xs: '48px', sm: '48px' },
    letterSpacing: '0.1px'
  },
  Menu_Item: {
    fontSize: { xs: 14, sm: 16 },
    lineHeight: { xs: '21px', sm: '24px' },
    letterSpacing: '0.15px'
  },
  Menu_Item_Dense: {
    fontSize: { xs: 12, sm: 14 },
    lineHeight: { xs: '24px', sm: '24px' },
    letterSpacing: '0.17px'
  },
  Tooltip_Label: {
    fontSize: { xs: 10, sm: 10 },
    lineHeight: { xs: '14px', sm: '14px' },
    fontWeight: 500
  },
  Table_Header: {
    fontSize: { xs: 14, sm: 14 },
    lineHeight: { xs: '24px', sm: '24px' },
    letterSpacing: '0.17px',
    fontWeight: 500
  }
}
