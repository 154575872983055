import { common } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

import { breakpointValues } from './breakingPoints'
import { themeColors } from './colors'
import { themeFontFamily, typographyValue } from './typography'

export const baseTheme = createTheme({
  breakpoints: {
    values: breakpointValues
  },
  typography: {
    fontFamily: themeFontFamily.primary,
    fontSize: 16,
    ...typographyValue
  },
  palette: {
    primary: {
      main: themeColors.orange[500]
    },
    secondary: {
      contrastText: common.white,
      main: themeColors.ink[90]
    },
    success: {
      contrastText: common.white,
      main: themeColors.lime[500]
    },
    warning: {
      contrastText: common.white,
      main: themeColors.lemon[500]
    },
    error: {
      contrastText: common.white,
      main: themeColors.grapefruit[500]
    },
    info: {
      contrastText: common.white,
      main: themeColors.azure[500]
    },
    singpass: {
      main: '#F4333D',
      dark: '#D33028'
    },
    ...themeColors
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none'
          },
          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.04)',
          border: '1px solid rgba(0, 0, 0, 0.24)',
          borderRadius: 12
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: themeColors.ink[90]
        }
      },
      defaultProps: {
        variantMapping: {
          // T1XL: 'h1',
          // T1: 'h1',
          // T2: 'h2',
          // T3: 'h3',
          // T4: 'h4',
          // T5: 'h5',
          // T6: 'h6',
          // T7: 'h6',
          // BodyText1: 'p',
          // BodyText2: 'p'
        }
      }
    },
    // MuiButtonBase: {
    //   styleOverrides: {
    //     root: {
    //       '&.sizeMedium': {
    //         fontSize: { xs: 12, sm: 14 }
    //       }
    //     }
    //   }
    // },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: 400,
          fontSize: 16,
          borderRadius: '8px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: themeColors.ink[90],
          fontWeight: 400,
          fontSize: 16,
          borderRadius: '8px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: themeColors.ink[90],
          fontWeight: 400,
          fontSize: 16
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '56px !important'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          // height: '48px'
        },
        indicator: {
          height: '4px',
          borderRadius: '2px 2px 0px 0px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 13,
          letterSpacing: '0.46px'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          fontWeight: 400,
          fontSize: 12,
          color: themeColors.ink[90]
        }
      }
    }
  }
})
